<template>
  <banner :list="list"></banner>
  <list :url="standardUrl" :filterFunc="standardMap" :show="true">
    <template v-slot:default="data">
      <product-card :list="data" name="standarddetail"></product-card>
    </template>
  </list>

</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import banner from "@/components/common/Swiper.vue";
import list from "@/components/common/list.vue";
import productCard from "@/components/common/productCard.vue";

//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import $ from "jquery";
import { throttle } from "@/utils/utils";

//ts 
import { standardMap } from "@/hooks/useProduct"


export default defineComponent({
  name: "product",
  components: {
    banner,
    list,
    productCard
  },
  setup() {
    const list = ref(null);
    const standardUrl = "/M/Server/GetProductLastbz";

    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
    });

    //下拉滚动

    function scroll() {
      const scrollTop = $(this).scrollTop();

      const navTop = 
      ($(".banner-swiper-container") as any).height() +
      ($(".menu-nav-wapper") as any).height();
      if (Number(scrollTop) >= Number(navTop)) {
        $(".menu-nav").css({
          position: "fixed",
          top: "1.467rem",
        });
      } else {
        $(".menu-nav").css({
          position: "static",
        });
      }
    }

    onMounted(() => {
      new Swiper(".recommend-container", {
        slidesPerView: 3,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true //修改swiper的父元素时，自动初始化swiper
      });
      $(window).on("scroll", throttle(scroll, 300));
    });

    onUnmounted(() => {
      $(window).off("scroll", throttle(scroll, 300));
    });

    return {
      list,
      standardUrl,
      standardMap,
    };
  }
});
</script>

<style lang="scss" scoped>
//  list  nav
</style>
